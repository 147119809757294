/**
 * ------------
 * SEARCH FORM
 * ------------
 * 
 * We just need a little script here to populate the value of the search field if there is a `q` param in the url
 * 
 */

export function query() {
    /** get value of `q` param from url, bail if there isn't one */
    const params = new URLSearchParams(window.location.search);
    const query = params.get('q');
    if (!query || query.length === 0) return;

    /** get reference to search input, and set its value */
    const searchInput: HTMLInputElement|null = document.querySelector('input#query[name="q"]');
    if (!searchInput) return;

    searchInput.value = query;
}
