/**
 * ------------
 * HEADER TOPBAR
 * ------------
 * 
 * #1 - we need to read in the height of the 'topbar' component so that we can set a CSS custom property accordingly
 * 
 */

const SELECTORS = {
    topbar: 'section.topbar',
}

/** #1 */
export function topbarHeight(): void {
    const topbar = document.querySelector(SELECTORS.topbar) as HTMLElement;
    if (!topbar) return;

    const componentHeight = topbar.getBoundingClientRect().height;
    document.documentElement.style.setProperty('--topbar-height', `${componentHeight}px`);
}
