/**
 * ------------
 * LIGHTBOX
 * ------------
 * 
 * The only behaviours required for the lightbox component are handling the opening/closing of the lightbox. This should, obviously,
 * bring the lightbox element itself either into/out of view, but we also need to handle the overflow of the page, as we don't want to
 * see the parent context's scrollbar over the lightbox when it is open.
 * 
 * There are also 'next' and 'prev' controls, to move through the images, when there's more than one
 * 
 */

const SELECTORS = {
    imageContainer: 'section.lightbox__image-container',
    prev: 'div.gallery-controls > button:first-of-type',
    next: 'div.gallery-controls > button:last-of-type',
    firstImage: 'section.lightbox__image-container ol > li:first-of-type',
}

export function init() {
    document.addEventListener('jlr:lightbox:show', () => {
        /** for now, we assume there is only one lightbox on the page - this may need to be extended in the future */
        const lightbox: HTMLElement | null = document.querySelector('section.lightbox');
        if (!lightbox) return;

        document.documentElement.style.overflow = 'hidden';
        lightbox.classList.add('show');
    });

    document.addEventListener('jlr:lightbox:hide', () => {
        /**
         * the 'hiding' of the lightbox is actually handled by the `dismiss` library, so here we just need to concern ourselves
         * with resetting the styles on the `html` element
         */
        document.documentElement.style.overflow = 'initial';
    });
}

export function controls() {
    /** before we bother attaching the event listeners, check if there is more than one image - if not, we don't need to do anything */
    const imageContainer = document.querySelector(SELECTORS.imageContainer) as HTMLElement;
    if (!imageContainer) return;
    const imageCount: number = parseInt(imageContainer.dataset.imageCount);
    if (imageCount === 0) return;

    const prevButton: HTMLButtonElement | null = document.querySelector(SELECTORS.prev);
    const nextButton: HTMLButtonElement | null = document.querySelector(SELECTORS.next);
    if (!prevButton || !nextButton) return;

    /** we move the list around by adjusting the margin of the first image, so we get a reference to that now */
    const firstImage: HTMLLIElement | null = document.querySelector(SELECTORS.firstImage);
    if (!firstImage) return;
    let itemWidth: number = window.innerWidth;
    console.log(itemWidth);

    nextButton.addEventListener('click', () => {
        const current: number = parseInt(imageContainer.dataset.imageCurrent);
        const next = current === imageCount - 1 ? 0 : current + 1;

        // update the UI
        imageContainer.dataset.imageCurrent = String(next);
        firstImage.style.marginInlineStart = `-${itemWidth * next}px`;
    });

    prevButton.addEventListener('click', () => {
        const current: number = parseInt(imageContainer.dataset.imageCurrent);
        const prev = current === 0 ? imageCount - 1 : current - 1;

        // update the UI
        imageContainer.dataset.imageCurrent = String(prev);
        firstImage.style.marginInlineStart = `-${itemWidth * prev}px`;
    });
}
