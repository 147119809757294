export interface Carousel {
    current: number,
    transition: number,
    interval: number | null,
    intervalDuration: number,
}

const SELECTORS = {
    slide: '.banner-carousel__slides > li',
    paginationControl: 'ol.pagination > li',
}

const CLASSES = {
    active: 'active',
}

/**
 * To carousel's height should match the height of the image of the current slide. This function gets that height,
 * and then sets it as a pixel value against the `--current-slide-height` custom property
 * 
 * @returns void | undefined
 * 
 */
export function setHeight(current: HTMLLIElement): void | undefined {
    const currentSlideHeight: number = current.getBoundingClientRect().height;
    document.documentElement.style.setProperty('--current-slide-height', `${currentSlideHeight}px`);
}

/**
 * Set the 'current' slide (the one the user will see in the viewport) according to a give index, i.e. if `2` is passed,
 * then the third slide in the list would be set as current
 * 
 * @param {number} index
 * @returns HTMLLIElement | null
 */
export function setCurrent(index: number): HTMLLIElement | null {
    const slides: Array<HTMLLIElement> = Array.from(document.querySelectorAll(SELECTORS.slide));
    if (!slides || slides.length === 0) return null;

    const current = slides[index];
    current.dataset.slideCurrent = 'true';
    delete current.dataset.slideNext;
    delete current.dataset.slidePrevious;
    /** now we've changed the current slide, we need to calculate the height of the component again, as it may be different */
    setHeight(current);

    return current;
}

/**
 * The 'current' slide has 2 neighbours: the 'next' slide and the 'previous' slide. We identify these with data-attributes.
 * The carousel also "wraps", such that the previous slide to the first slide, is the last slide etc
 * 
 * @param {number} currentIndex 
 * @param {number} wait 
 * @returns [HTMLLIElement, HTMLLIElement] | null
 */
export function setNeighbours(currentIndex: number, wait: number = 0): [HTMLLIElement, HTMLLIElement] | null {
    const slides: Array<HTMLLIElement> = Array.from(document.querySelectorAll(SELECTORS.slide));
    if (!slides || slides.length === 0) return null;

    let previousIndex: number;
    let nextIndex: number;
    if (currentIndex === 0) {
        /** current slide is the first slide, previous is therefore the last slide */
        previousIndex = slides.length - 1;
        nextIndex = currentIndex + 1;
    } else if (currentIndex === slides.length - 1) {
        /** current slide is the last slide, next is therefore the first slide */
        previousIndex = currentIndex - 1;
        nextIndex = 0;
    } else {
        previousIndex = currentIndex - 1;
        nextIndex = currentIndex + 1;
    }

    /** any irrelevant slides (those that are not current, next or previous) can have all data-attrs removed */
    slides.forEach((slide, index: number) => {
        if (![currentIndex, previousIndex, nextIndex].includes(index)) {
            delete slide.dataset.slidePrevious;
            delete slide.dataset.slideNext;
            delete slide.dataset.slideCurrent;
        }
    });

    /** handle previous */
    const previous = slides[previousIndex];
    /** we use a timeout here, as we don't want to remove the `current` attribute until the animation has completed */
    setTimeout(() => delete previous.dataset.slideCurrent, wait);
    delete previous.dataset.slideNext;
    previous.dataset.slidePrevious = 'true';

    /** handleNext */
    const next = slides[nextIndex];
    delete next.dataset.slideCurrent;
    delete next.dataset.slidePrevious;
    next.dataset.slideNext = 'true';

    return [previous, next];
}

/**
 * The `step` function is responsible for tracking the index of the 'current' slide. Current implementation is that
 * it always moves forwards, but that could be easily extended
 * 
 * @returns number | null
 */
export function step(): number | null {
    const slides: Array<HTMLLIElement> = Array.from(document.querySelectorAll(SELECTORS.slide));
    if (slides.length === 0) return null;

    const currentIndex = slides.map(slide => slide.hasAttribute('data-slide-current')).indexOf(true);
    const slideCount = slides.length;

    let newIndex: number;
    if (currentIndex === slideCount - 1) {
        /** we're on the last slide, loop back to the beginning */
        newIndex = 0;
    } else {
        newIndex = currentIndex + 1;
    }

    return newIndex;
}

export function play(carousel: Carousel) {
    carousel.interval = window.setInterval(() => {
        carousel.current = step();
    }, carousel.intervalDuration);
}

export function pause(carousel: Carousel) {
    window.clearInterval(carousel.interval);
}

/**
 * The UI also needs to reflect the current slide in the "pagination" component that runs along the bottom of the banner
 * We use the index of the current slide to update the styling of the relevant pagination control - this relies on the number
 * of slides and the number of pagination controls being the same, but that _should_ always be the case
 * 
 * @param {number} currentIndex 
 */
export function setPagination(currentIndex: number): void {
    const paginationControls: Array<HTMLLIElement> = Array.from(document.querySelectorAll(SELECTORS.paginationControl));
    paginationControls.forEach(ctrl => ctrl.classList.remove(CLASSES.active));
    paginationControls[currentIndex].classList.add(CLASSES.active);
}