/**
 * ------------
 * TABS
 * ------------
 * 
 * Tabs require a small amount of scripting to set the 'active' tab on page load. Each tab is a link to a different page,
 * so don't need to worry about switching the subnav depending on the selected top-level item etc, we just need to check which
 * tab contains the link to the current page, and then have that as "active". We fall back to the first item if none of the tabs
 * contain a link to the current page
 * 
 * Furthermore, we need to handle the secondary layer of tabs. These should display as scrollable list if they overflow the container,
 * with an arrow at either end of the list to move the user through the items
 * 
 */

const SELECTORS = {
    mainItems: 'section.tabs ul.tabs__main > li',
    subItems: 'section.tabs ul.tabs__secondary > li',
    subItemsList: 'section.tabs ul.tabs__secondary',
    subItemsControls: 'section.tabs .tabs__secondary-controls > button',
}

interface Tabs {
    mainItems: Array<HTMLLIElement>,
    subItems: Array<HTMLLIElement>,
    subItemsControls: Array<HTMLButtonElement>,
    /**
     * this is being typed a string value of 'true' or 'false' as Typescript shows an error if I true and 
     * use a `boolean` type here, or a number, or an enum etc, which is ridiculous and I can't work out why,
     * so just using a string for now to get rid of the red squiggly
     * 
     */
    subItemsOverflow: 'true'|'false',
    active: string,
}

const target: Tabs = {
    mainItems: [],
    subItems: [],
    subItemsControls: [],
    subItemsOverflow: 'false',
    active: '',
}

const tabs = new Proxy(target, {
    set(target, property: keyof Tabs, value) {
        target[property] = value;

        if (property === 'active') {
            /** we expect the value here to be the url for the current page */
            if (target.mainItems.length === 0) return true;

            /** default the 'active' tab to be the first in the list */
            let activeMainItem: HTMLLIElement = target.mainItems[0];
            let activeSubItem: HTMLLIElement = target.subItems[0];

            /** iterate over the tabs, and set the active one if we find a match with the url */
            target.mainItems.forEach(item => {
                const link = item.querySelector('a')?.getAttribute('href') || "";
                if (target.active.includes(link)) activeMainItem = item;
            });

            target.subItems.forEach(item => {
                const link = item.querySelector('a')?.getAttribute('href') || "";
                if (target.active.includes(link)) activeSubItem = item;
            });

            activeMainItem.setAttribute("aria-selected", "true");
            activeSubItem.setAttribute("aria-selected", "true");
        }

        if (property === 'subItemsOverflow') {
            /** there is overflow, so we need to show the controls */
            const subItemsList: HTMLElement|null = document.querySelector(SELECTORS.subItemsList);
            if (!subItemsList) return;
            
            if (value === 'true') subItemsList.classList.add('has-overflow');
            else subItemsList.classList.remove('has-overflow');
        }

        if (property === 'subItemsControls' && value.length > 0) {
            const subItemsList: HTMLElement|null = document.querySelector(SELECTORS.subItemsList);
            if (!subItemsList) return;

            /** loop over the controls and attach event listeners */
            target.subItemsControls.forEach(ctl => {
                ctl.addEventListener('click', (event) => {
                    let target = event.target as HTMLButtonElement|HTMLDivElement;
                    if (/div/i.test(target.nodeName)) target = target.parentElement as HTMLButtonElement;
                    
                    if (target.classList.contains('next')) {
                        /** HANDLE NEXT */
                        subItemsList.scrollTo({ left: subItemsList.scrollLeft + 100, behavior: 'smooth' });
                    } else if (target.classList.contains('prev')) {
                        /** HANDLE PREV */
                        subItemsList.scrollTo({ left: subItemsList.scrollLeft - 100, behavior: 'smooth' });
                    }
                });
            });
        }
        
        return true;
    },
});

export function init() {
    if (!document.querySelector('section.tabs')) return;

    tabs.mainItems = Array.from(document.querySelectorAll(SELECTORS.mainItems));
    tabs.subItems = Array.from(document.querySelectorAll(SELECTORS.subItems));
    tabs.active = window.location.pathname.replace('.html', '');
    
    /**
     * check for sub items overflow and set the property accordingly - also setup eventlisteners
     * for the buttons click
     * 
     */
    const subItemsList: HTMLUListElement = document.querySelector(SELECTORS.subItemsList);

    document.documentElement.style.setProperty('--secondary-tabs-height', `${subItemsList.getBoundingClientRect().height}px`);
    tabs.subItemsControls = Array.from(document.querySelectorAll(SELECTORS.subItemsControls));

    if (subItemsList && subItemsList.scrollWidth > subItemsList.clientWidth) {
        tabs.subItemsOverflow = 'true';
    }

    window.addEventListener('resize', () => {
        if (subItemsList && subItemsList.scrollWidth > subItemsList.clientWidth) {
            tabs.subItemsOverflow = 'true';
        } else {
            tabs.subItemsOverflow = 'false';
        }
    });
}
