/**
 * ------------
 * DISMISS
 * ------------
 * 
 * The role of any button marked `data-dismiss-trigger` should be to close the closest ancestral element
 * with a `data-dismiss-target` attribute - this is achieved by removing one of 'show' or 'open' classes, depending on the element
 * 
 */

export function dismissables() {
    const triggers: Array<HTMLButtonElement> = Array.from(document.querySelectorAll('button[data-dismiss-trigger]'));
    if (!triggers || triggers.length === 0) return;

    triggers.forEach(trigger => {
        trigger.addEventListener('click', () => {
            const parent = trigger.closest('*[data-dismiss-target]');
            if (!parent) return;
            if (parent.getAttribute('id') && window.location.hash.includes(parent.getAttribute('id'))) {
                /**
                 * In this scenario, we know that the 'dismissable' parent has an id, and that the id in question
                 * matches the url's hash. E.g. if a user opens a video-modal, they do so by effectively navigating to,
                 * for example, `/gl/en#video-modal-test`. So if a div with id `video-modal-test` is "dismissed", and that
                 * ID appears in the URL, then we want the method of dismissal to be a 'back' navigation, effectively removing
                 * the hash from the url
                 * 
                 */
                window.history.back();
            } else {
                parent.classList.remove('show', 'open');
            }
        });
    });
}