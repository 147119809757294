/**
 * ------------
 * EXTERNAL LINK
 * ------------
 * 
 * A button with a `data-link-external` attribute is to be treated as a link-intercept, i.e. the button triggers a modal popup
 * that asks the user to confirm that they want to follow the link - they are then redirected to the target page. Therefore, it is a 
 * requirement that such buttons also have a `data-target` attribute that defines the eventual link that the button click should
 * redirect to
 * 
 */

export function externalLinks() {
    const links: Array<HTMLButtonElement> = Array.from(document.querySelectorAll('button[data-link-external][data-target]'));
    if (!links || links.length === 0) return;

    links.forEach(link => {
        link.addEventListener('click', () => {
            const { target } = link.dataset;
            if (!target) return;

            /** for now, we know there is only going to be one modal on the site, but this should be made into a more generic solution at some point */
            const modal: HTMLElement|null = document.querySelector('aside.confirm-modal');
            if (!modal) return;
            modal.classList.add('show');

            const anchor = modal.querySelector('.buttons-container > a');
            if (!anchor) return;

            anchor.setAttribute('href', target);
            anchor.setAttribute('rel', 'noopener nofollow noreferrer');
            anchor.setAttribute('target', '_blank');
        });
    });
}