/**
 * ------------
 * TOGGLE VISIBILITY
 * ------------
 * 
 * This function handles the behaviour of showing/hiding a target component when it's "controller" is clicked.
 * The component is hidden on page load, but should then have its visibility toggled by the controller.
 * 'Controllers' are defined by having a `data-visibility-toggle` attribute. They must also have an `aria-controls` 
 * attribute, with an value that matches the `id` of the target component. A warning will be shown in the console 
 * if that attribute is missing.
 * 
 */
export function toggleVisibility(): void {
    /** get a reference to the control button and the section it controls */
    const controlBtns = Array.from(document.querySelectorAll('button[data-visibility-toggle][aria-controls]')) as Array<HTMLButtonElement>;
    if (!controlBtns || controlBtns.length === 0) return;

    controlBtns.forEach(button => {
        if (!button.hasAttribute('aria-controls')) {
            console.warn('Button must have an `aria-controls` attribute');
            return;
        }
        const component = document.querySelector(`#${button.getAttribute('aria-controls')}`) as HTMLElement;
        if (!component) return;
    
        /**
         * handle control button click:
         * 
         * #1 - add an `open` class to the component
         * #2 - set the 'aria-expanded' attribute of the button to 'true' or 'false' depending on if we're showing or hiding the section
         * 
         */
        button.addEventListener('click', () => {
            const isHidden: boolean = !component.classList.contains('open') || component.classList.contains('closed');
    
            /**
             * if 'isHidden === true', then the click should show the component;
             * if 'isHidden === false', then the click should hide the component;
             *
             */
            if (isHidden) {
                component.classList.add('open');
                component.classList.remove('closed');
            } else {
                component.classList.add('closed');
                component.classList.remove('open');
            }

            button.setAttribute('aria-expanded', isHidden ? 'true' : 'false');
        });
    });

}