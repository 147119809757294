/**
 * For every 'carousel' on the page, we need to attach click event handlers to its 'previous' and 'next' controls.
 * We also need to correctly show/hide the carousel controls depending on whether or not there is overflow
 * 
 * @returns void | null
 */

const SELECTORS = {
    carousel: 'section.carousel',
    list: 'ul',
    listItem: 'ul > li',
    controls: 'button[id*="--prev"], button[id*="--next"]',
}

export function carouselControls(): void | null {
    /** get a reference to all of the carousels on the page, and return early if there aren't any */
    const carousels = Array.from(document.querySelectorAll(SELECTORS.carousel));
    if (!carousels || carousels.length === 0) return null;

    /** iterate on the carousels and, for each, setup the eventlisteners */
    carousels.forEach(carousel => {
        /** the 'list' contains the carousel items */
        const list: HTMLUListElement = carousel.querySelector(SELECTORS.list);

        /** determine if there is overflow */
        if (list.scrollWidth > list.clientWidth) carousel.classList.add('has-overflow');

        /**
         * the `listItem` is the first item within the carousel's list. We need this as we rely on the item's width to handle the
         * movement of the carousel correcty, note that it is assumed that every item in the carousel will have the same width
         * 
         */
        const listItem: HTMLLIElement = Array.from(carousel.querySelectorAll(SELECTORS.listItem))[0] as HTMLLIElement;
        /** controls are the 2 buttons - note that it is assumed these will have `--prev` and `--next` _somewhere_ in their respective IDs */
        const controls: Array<HTMLElement> = Array.from(carousel.querySelectorAll(SELECTORS.controls));
        controls.forEach(control => {
            /** for each of the two buttons, add a click event handler */
            control.addEventListener('click', (event: Event) => {
                const target = event.target as HTMLButtonElement;
    
                /**
                 * for both buttons, we want to get the current scrollLeft of the list, and then either add (for next),
                 * or subtract (for prev) the width of one list item
                 * 
                 */
                const listItemWidth = listItem.getBoundingClientRect().width;
                const currentScrollPosition = list.scrollLeft;
                if (/next/i.test(target.getAttribute('id'))) {
                    /** NEXT BUTTON CLICKED */
                    list.scroll({ left: currentScrollPosition + listItemWidth, behavior: 'smooth' });

                    if (list.scrollLeft >= (list.scrollWidth - list.clientWidth)) {
                        /** if this is true, we've hit the right-hand edge, and therefore move the first item to the end of the list */
                    }
                } else {
                    /** PREV BUTTON CLICKED */
                    list.scroll({ left: currentScrollPosition - listItemWidth, behavior: 'smooth'});

                    if (list.scrollLeft === 0) {
                        /** if this is true, we've hit the left-hand edge, and therefore move the last item to the start of the list */
                    }
                }
            });
        });
    });
}
