/**
 * ------------
 * WISHLIST
 * ------------
 * 
 * #1 - Item added. When an item is added to a wishlist, the page reloads with a `added=true` url param. When this is present,
 * a little popup message next to the wishlist menu item should appear, saying that an item has been added
 * 
 */

function createMessage(text: string): HTMLDivElement {
    const message = document.createElement('div');
    const messageContents = document.createElement('p');
    messageContents.innerHTML = text;

    message.classList.add('bubble');
    message.appendChild(messageContents);
    return message;
}

export function handleParams() {
    /** check the page URL for the `added` param */
    const params = new URLSearchParams(window.location.search);
    /** find the 'wishlist' menu item, we'll need this for both `added` and `error` messages */
    const wishlistMenuItem = document.querySelector('nav.utility li.wishlist');
    if (!wishlistMenuItem) return;

    /**
     * read in the value of the 'bubble fade out wait' CSS custom prop, this tells us how long
     * to wait for, before removing the message from the DOM
     */
    const delay: number = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--bubble-fade-out-wait'));

    let message: HTMLDivElement;
    if (params.get('added') && params.get('added') === 'true') {
        message = createMessage('Added to Wishlist');
        wishlistMenuItem.appendChild(message);
        setTimeout(() => message.remove(), delay);
    }

    if (params.get('error') && params.get('error') === 'true') {
        message = createMessage('Maximum items added. Unable to add any more.');
        wishlistMenuItem.appendChild(message);
        setTimeout(() => message.remove(), delay);
    }

}