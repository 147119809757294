import * as events from './events';

/** COMPONENTS */
import * as bannerCarousel from './components/banner-carousel';
import { carouselControls } from './components/carousel';
import { topbarHeight } from './components/header-topbar';
import * as lightbox from './components/lightbox';
import * as print from './components/print';
import * as searchForm from './components/search-form';
import * as tabs from './components/tabs';
import * as wishlist from './components/wishlist';

/** LIBRARIES */
import { toggleVisibility } from './libs/toggle-visibility';
import { dismissables } from './libs/dismiss';
import { externalLinks } from './libs/external-link';

window.addEventListener('load', () => {
    /** COMPONENTS */
    bannerCarousel.init();
    carouselControls();
    lightbox.init();
    lightbox.controls();
    print.init();
    tabs.init();
    searchForm.query();
    topbarHeight();
    wishlist.handleParams();

    /** LIBRARIES */
    toggleVisibility();
    dismissables();
    externalLinks();

    /** EVENTS */
    Array.from(document.querySelectorAll('*[data-video-modal-show]')).forEach(node => {
        node.addEventListener('click', () => document.dispatchEvent(events.VIDEO_MODAL_SHOW));
    });

    Array.from(document.querySelectorAll('*[data-video-modal-hide]')).forEach(node => {
        node.addEventListener('click', () => document.dispatchEvent(events.VIDEO_MODAL_HIDE));
    });

    Array.from(document.querySelectorAll('*[data-lightbox-show]')).forEach(node => {
        node.addEventListener('click', () => document.dispatchEvent(events.LIGHTBOX_SHOW));
    });

    Array.from(document.querySelectorAll('*[data-lightbox-hide]')).forEach(node => {
        node.addEventListener('click', () => document.dispatchEvent(events.LIGHTBOX_HIDE));
    });
});
