import {
    pause,
    play,
    setCurrent,
    setHeight,
    setNeighbours,
    setPagination,
    step,
    Carousel
} from './lib';

/**
 * ------------
 * BANNER CAROUSEL
 * ------------
 * 
 * #1 - We need to determine the height of the 'current' slide so the container can match. Since the slides are positioned
 * absolutely, the height of the containing element collapses to 0 - this is no good.
 * 
 * #2 - We need to move around the data-attributes that describe the order of the slides. The transitions themselves should 
 * all be controlled in the CSS, the script should do nothing more that set the correct attributes/class names
 * 
 * #3.a - We need to populate the 'pagination' element with the correct number of controls for the number of slides in question
 * 
 * #3.b - We need to setup the 'pagination' controls such that clicking on one of the numbers brings that slide immediately 
 * into view, and then updates the 'current', 'next' and 'prev' attributes accordingly
 * 
 */

const SELECTORS = {
    banner: '.banner-carousel',
    pagination: '.banner-carousel ol.pagination',
    slide: '.banner-carousel__slides > li',
    listItem: 'li',
}

const target: Carousel = {
    /** default to the first slide - this could eventually be a value read in from a data-attr if client wanted to be able to set a different starting position? */
    current: 0,
    /** a value in miliseconds for how long the slide-change animation takes */
    transition: parseInt(getComputedStyle(document.documentElement).getPropertyValue('--slide-change-duration')),
    /** when `interval` has a value, it is the ID for the current 'setInterval' function that is running, we store this so we can clear it as required */
    interval: null,
    /** a value in miliseconds for how long each slide should appear on screen for before transitioning to the next */
    intervalDuration: 8000,
}

/**
 * Set up a proxy to our Carousel object. This pattern allows us a to keep most of the logic in one place. Updating the carousel,
 * or changing the slide, then becomes as simple as `carousel.current = 2` where "2" represents any arbitrary index of a slide in
 * the carousel
 * 
 */
const carousel = new Proxy(target, {
    set(target, property: keyof Carousel, value) {
        target[property] = value;
    
        if (property === 'current') {
            setCurrent(value);
            if (Array.from(document.querySelectorAll(SELECTORS.slide)).length > 1) {
                setPagination(value);
                setNeighbours(value, target.transition);
            }
        }

        return true;
    },
});

/**
 * Set everything up, get all of the values and DOM references that we need
 */
export function init() {
    /** get a reference to the banner, and return if there isn't one */
    const banner = document.querySelector(SELECTORS.banner);
    if (!banner) return;

    
    /** get a reference to the pagination element, and return if there isn't one */
    const pagination: HTMLOListElement = document.querySelector(SELECTORS.pagination);
    if (!pagination) return;
    
    /** populate the `pagination` list with an "li" for each slide. The aria-controls attribute should correspond to the ID of one of the slides */
    const slides: Array<HTMLLIElement> = Array.from(document.querySelectorAll(SELECTORS.slide));
    const slideCount: number = slides.length;
    if (slideCount > 1) {
        for (let i = 1; i <= slideCount; i++) {
            const control = document.createElement('li');
            control.innerHTML = String(i);
            control.setAttribute('aria-controls', `slide-${i}`);
            pagination.appendChild(control);
        }

        /**
         * each LI in the pagination elements needs a click listener, to run a function that sets the carousel's current
         * slide to the corresponding slide for the control, and resets the interval that handles the slide transition
         * 
         */
        const paginationControls: Array<HTMLLIElement> = Array.from(pagination.querySelectorAll(SELECTORS.listItem));
        if (paginationControls.length > 0) {
            paginationControls.forEach((control, index: number) => {
                control.addEventListener('click', () => {
                    carousel.current = index;
                    window.clearInterval(carousel.interval);
                    carousel.interval = window.setInterval(() => {
                        carousel.current = step();
                    }, carousel.intervalDuration);
                });
            });
        }
    }


    /** call `setHeight` once on load, to get the height of the "default" active slide (this will normally be the first one) */
    setHeight(slides[0]);
    /** initialise carousel to first slide */
    carousel.current = 0;

    /**
     * Move the slides on at a regular interval
     * 
     */
    if (slideCount > 1) play(carousel);

    document.addEventListener('jlr:video-modal:show', () => {
        console.log('jlr:video-modal:show');
        pause(carousel);
    });
    document.addEventListener('jlr:video-modal:hide', () => play(carousel));
}
