export function init() {
    const printItems: Array<HTMLLIElement> = Array.from(document.querySelectorAll('li#print'));
    if (!printItems || printItems.length === 0) return;

    printItems.forEach(item => {
        const button: HTMLButtonElement | null = item.querySelector('button');
        if (!button) return;

        button.addEventListener('click', () => {
            window.print();
        });
    });
}
